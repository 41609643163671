<!-- outgoingMailboxManagement -->
<template>
  <div class="w center_all outgoingMailboxManagement">
    <div class="center_2">
      <ul class="crumbs clearfix">
        <li>邮件传播</li>
        <li>-></li>
        <li>发件邮箱管理</li>
      </ul>
      <div class="push-days mb-20">
        <span>本刊任务连续推送天数：</span>
        <el-input style="width:200px" placeholder="请输入" v-model="pushContinueDay" @change="pushDayChange"></el-input>
      </div>
      <!-- 新增任务按钮 -->
      <el-button class="new-btn fl" size="small" round icon="el-icon-plus" @click="newEmail">新增</el-button>
      <!-- 新增任务弹框 -->
      <el-dialog :show-close="true" :visible.sync="newEmailDialogVisible" width="600px" title="新增邮箱">
        <el-form ref="newEmailForm" :model="newEmailForm" :rules="newTaskRules" status-icon label-width="120px">
          <el-form-item label="发件邮箱：" size="zdy" prop="username">
            <el-input v-model="newEmailForm.username" />
          </el-form-item>
          <el-form-item label="邮件服务域名：" size="zdy" prop="host">
            <el-input v-model="newEmailForm.host" />
          </el-form-item>
          <el-form-item label="端口：" size="zdy" prop="port">
            <el-input v-model="newEmailForm.port" />
          </el-form-item>
          <el-form-item label="密码（密钥）：" size="zdy" prop="password">
            <el-input v-model="newEmailForm.password" />
          </el-form-item>
          <el-form-item label="日发送量：" size="zdy" prop="limitCount">
            <el-input v-model.number="newEmailForm.limitCount" placeholder="请输入数字" />
          </el-form-item>
          <el-form-item label="间接发送时长(s)：" size="zdy" prop="intervalSecond">
            <el-input v-model.number="newEmailForm.intervalSecond" />
          </el-form-item>
          <el-form-item label="发件人姓名：" size="zdy" prop="personalName">
            <el-input v-model="newEmailForm.personalName" />
          </el-form-item>
          <el-form-item label="收件邮箱规则：" size="zdy" prop="receiveEmailHost">
            <el-input v-model="newEmailForm.receiveEmailHost" />
          </el-form-item>
          <el-form-item label="收件邮箱域名优先级：" size="zdy" prop="receiveEmailHostSequence">
            <el-input v-model="newEmailForm.receiveEmailHostSequence" />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="newEmailDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="newEmailBtn('new')"> 确 定</el-button>
        </span>
      </el-dialog>
      <!-- 导入结果弹框 -->
      <el-dialog :show-close="true" :visible.sync="importResultDialogVisible" width="400px" title="导入结果">
        <p>导入文件共{{ importResultData.importTotalCount }}条数据</p>
        <p>
          成功{{ importResultData.successNumber }}条 / 重复{{ importResultData.repetitionNumber }}条 /
          <span :class="[redColor]">失败{{ importResultData.failNumber }}条</span>
        </p>
        <span v-if="importResultData.failNumber > 0" slot="footer" class="dialog-footer">
          <el-button @click="importResultDialogVisible = false">关闭</el-button>
          <el-button type="primary" @click="exportFailedRecord">导出失败记录</el-button>
        </span>
        <span v-else slot="footer" class="dialog-footer">
          <el-button @click="importResultDialogVisible = false">知道了</el-button>
        </span>
      </el-dialog>
      <!-- 任务列表 -->
      <SimpleTable
        class="emailTableList"
        ref="emailTableList"
        showChoose
        showUpBottom
        showDelete
        showOperate
        showOperateTooltip
        showUploadFile
        isDisabledClick
        showAddColumn
        btn1Text="查看"
        serialText="优先级"
        showOperateWidth="150px"
        showOperateText="收件邮箱"
        showOperateTooltiplabel="导入样例"
        uploadFileBtnText="导入"
        :showBtn2="false"
        :showOperateTooltipContent="showOperateTooltipContent"
        :tableData="emailTableData"
        :columnData="emailTableColumn"
        :initObj="emailTablePageObj"
        :fileUploadUrl="ImportEmailUploadUrl"
        :fileUploadForm="emailExportForm"
        :addColumn="addColumn"
        @addColumnClick="addColumnClick"
        @onRowClick="handleEmailRowClick"
        @onClickBtn1="handleViewFile"
        @onChangeInputBlur="handleChangeInputBlur"
        @beforeUploadFile="beforeUploadFile"
        @uploadFileSuccess="uploadFileSuccess"
        @onChangeDeleteItems="handleEmailDeleteItems"
        @onChangeToTop="handleEmailToTop"
        @onChangeToBottom="handleEmailToBottom"
        @onChangePageData="
          (pageObj) => {
            getEmailList(pageObj)
          }
        "
      >
      </SimpleTable>
      <el-dialog :show-close="true" :visible.sync="importEmailListDialogVisible" width="600px" title="收件邮箱">
        <SimpleTable
          class="importEmailList"
          ref="importEmailList"
          showChoose
          showDelete
          :tableData="exportEmailTableData"
          :columnData="exportEmailTableColumn"
          :initObj="exportEmailTablePageObj"
          @onRowClick="handleExportEmailRowClick"
          @onChangeDeleteItems="handleExportEmailDeleteItems"
          @onChangePageData="
            (pageObj) => {
              getExportEmailList(pageObj)
            }
          "
        ></SimpleTable>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import SimpleTable from '@/components/SimpleTable'
import { DownloadByRecordId, DownloadFailRecordByTaskValue } from '@/api/selfPush'
import { GetEmailList, UpdatePushContinueDay, SaveNewEmail, ImportEmailUploadUrl, DeleteEmail, EmailUpOrBottom, ExportEmailList, DeleteExportEmailListItem, Refresh } from '@/api/emailPush'
import { GetMagDetail } from '@/api/detail'
import { mapGetters } from 'vuex'
export default {
  name: 'OutgoingMailboxManagement',
  data() {
    const validateUsername = (rule, value, callback) => {
      let re = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/
      if (re.test(value)) {
        callback()
      } else {
        callback(new Error('请输入格式正确的邮箱！'))
      }
    }
    return {
      ImportEmailUploadUrl: ImportEmailUploadUrl,
      basicInfo: {},
      pushContinueDay: 0,
      showOperateTooltipContent: require('@/assets/img/importExample.png'),
      newEmailDialogVisible: false, // 新增邮箱
      newEmailForm: {
        username: '',
        host: '',
        port: '',
        password: '',
        limitCount: '',
        receiveEmailHost: '',
        intervalSecond: '',
        personalName: '',
        receiveEmailHostSequence: ''
      },
      newTaskRules: {
        host: [{ message: '请填写邮件服务域名', trigger: 'blur', required: true }],
        username: [
          {
            message: '请输入格式正确的邮箱',
            validator: validateUsername,
            trigger: 'blur',
            required: true
          }
        ],
        limitCount: [{ message: '请填写日发送量', trigger: 'blur', required: true }],
        password: [{ message: '请填写密码（密钥）', trigger: 'blur', required: true }]
      },
      emailTableData: [],
      emailTableColumn: [
        {
          title: '发件邮箱',
          prop: 'username',
          notSortable: true,
          edit: true
        },
        {
          title: '邮件服务域名',
          prop: 'host',
          notSortable: true,
          edit: true,
          width: '100px'
        },
        {
          title: '端口',
          prop: 'port',
          notSortable: true,
          edit: true
        },
        {
          title: '密码（密钥）',
          prop: 'password',
          notSortable: true,
          edit: true,
          width: '100px'
        },
        {
          title: '日发送量',
          prop: 'limitCount',
          notSortable: true,
          edit: true
        },
        {
          title: '间隔发送时长(s)',
          prop: 'intervalSecond',
          notSortable: true,
          edit: true,
          width: '120px'
        },
        {
          title: '发件人姓名',
          prop: 'personalName',
          notSortable: true,
          edit: true,
          width: '90px'
        },
        {
          title: '收件邮箱规则',
          prop: 'receiveEmailHost',
          notSortable: true,
          width: '170px',
          edit: true,
          tooltip: true,
          tooltipContent: 'founder@founder.com的邮箱域名需要填写founder.com，多个域名请以英文分号;隔开，例如founder.com;gmail.com，未填写的表示任意任域名的邮箱',
          tooltipIsIcon: false,
          tooltipText: '填写说明'
        },
        {
          title: '收件邮箱域名优先级',
          prop: 'receiveEmailHostSequence',
          notSortable: true,
          edit: true,
          width: '160px'
        }
      ],
      emailTablePageObj: {
        page: 1,
        size: 10
      },
      importResultDialogVisible: false, // 导入结果
      emailExportForm: {
        magEmailId: ''
      },
      chooseEmailItem: '',
      importEmailListDialogVisible: false,
      exportEmailTableData: [],
      exportEmailTableColumn: [
        {
          title: '收件邮箱',
          prop: 'email',
          notSortable: true
        }
      ],
      exportEmailTablePageObj: {
        page: 1,
        size: 10
      },
      chooseExportEmailItem: '',
      importResultData: {},
      addColumn: {
        label: '符合该规则的全量学者数',
        width: '220px'
      }
    }
  },
  props: {},
  components: {
    SimpleTable
  },
  computed: {
    ...mapGetters({
      magId: 'magId'
    }),
    redColor() {
      return this.importResultData.failNumber && this.importResultData.failNumber > 0 ? 'red-color' : ''
    }
  },
  watch: {
    magId() {
      this.getMagDetail()
      this.getEmailList()
    }
  },
  mounted() {
    this.getMagDetail()
    this.getEmailList()
  },
  methods: {
    // 获取当前期刊基本信息
    getMagDetail() {
      const params = {
        id: this.magId
      }
      GetMagDetail(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.basicInfo = data
          this.pushContinueDay = data.pushContinueDay
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 修改本刊任务连续推送天数
    pushDayChange() {
      let params = {
        magId: this.magId,
        pushContinueDay: this.pushContinueDay
      }
      UpdatePushContinueDay(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.getMagDetail()
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 新增邮箱
    newEmail() {
      this.newEmailDialogVisible = true
      this.$nextTick(() => {
        this.$refs.newEmailForm.clearValidate()
      })
    },
    // 新增邮箱确定
    newEmailBtn() {
      this.$refs['newEmailForm'].validate((valid) => {
        if (valid) {
          let params = { magId: this.magId, ...this.newEmailForm }
          SaveNewEmail(params).then((res) => {
            if (res && res.data && res.data.status === 0) {
              this.$message({
                type: 'success',
                message: '新增成功'
              })
              this.newEmailDialogVisible = false
              this.getEmailList()
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        }
      })
    },
    // 获取邮箱列表
    getEmailList(pageParams = {}, type, item) {
      let { page = 1, size = 10 } = pageParams
      let params = {
        magId: this.magId,
        page: page - 1,
        size
      }
      GetEmailList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.emailTablePageObj = {
            ...this.emailTablePageObj,
            page,
            size,
            total: data.totalElements
          }
          this.emailTableData = data.content
          if (this.emailTableData.length > 0) {
            this.emailTableData.map((item, index) => {
              item.activeBtn1 = item.hasAddEmail
              item.refreshTime = item.refreshTime || '--'
              item.magAuthorEmailCount = item.magAuthorEmailCount || '--'
              item.addColumnContent =
                '<div class="addColumnContent">' +
                '<div class="top">' +
                '<div class="count">数量：' +
                item.magAuthorEmailCount +
                '</div>' +
                '<div class="refresh">刷新</div>' +
                '</div>' +
                '<div class="bottom">刷新时间：' +
                item.refreshTime +
                '</div>' +
                '</div>'
            })
            this.$nextTick(() => {
              if (type === 'move' || type === 'edit') {
                this.$refs.emailTableList.handleRowClick(item)
              }
              // else if (type === 'edit') {
              //   this.taskListData.map((it) => {
              //     if (it.id === item) {
              //       this.$refs.taskList.handleRowClick(it)
              //     }
              //   })
              // }
              else {
                this.$refs.emailTableList.handleRowClick(this.emailTableData[0])
              }
            })
          } else {
            this.$refs.emailTableList.initData()
            this.handleEmailRowClick('')
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 处理邮箱列表为input框时失焦事件
    handleChangeInputBlur(val, prop, event, index, content) {
      let params = {
        magId: this.magId,
        id: this.emailTableData[index].id,
        username: prop === 'username' ? content : this.emailTableData[index].username,
        host: prop === 'host' ? content : this.emailTableData[index].host,
        port: prop === 'port' ? content : this.emailTableData[index].port,
        password: prop === 'password' ? content : this.emailTableData[index].password,
        limitCount: prop === 'limitCount' ? content : this.emailTableData[index].limitCount,
        receiveEmailHost: prop === 'receiveEmailHost' ? content : this.emailTableData[index].receiveEmailHost,
        receiveEmailHostSequence: prop === 'receiveEmailHostSequence' ? content : this.emailTableData[index].receiveEmailHostSequence,
        intervalSecond: prop === 'intervalSecond' ? content : this.emailTableData[index].intervalSecond,
        personalName: prop === 'personalName' ? content : this.emailTableData[index].personalName
      }
      SaveNewEmail(params).then((resp) => {
        let { status, message } = resp.data
        if (status !== 0) {
          this.$message({
            type: 'error',
            message: message
          })
        } else {
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
          this.getEmailList(this.emailTablePageObj, 'edit', this.emailTableData[index])
        }
      })
    },
    // 点击邮箱列表每一行
    handleEmailRowClick(chooseId) {
      this.emailTableData.map((item) => {
        if (item.id === chooseId) {
          this.chooseEmailItem = item
        }
      })
      this.$emit('changeTask', chooseId)
    },
    // 上传文件之前的钩子
    beforeUploadFile(file) {
      this.emailExportForm.magEmailId = this.chooseEmailItem.id
    },
    // 导入文件成功后
    uploadFileSuccess(data) {
      this.getEmailList(this.emailTablePageObj, 'edit', this.chooseEmailItem)
      this.importResultDialogVisible = true
      this.importResultData = data
    },
    // 查看导入收件邮箱记录
    handleViewFile() {
      this.importEmailListDialogVisible = true
      this.getExportEmailList()
    },
    // 导入收件邮箱
    // handleImportFile() {},
    // 导出失败记录
    exportFailedRecord() {
      let url = DownloadFailRecordByTaskValue + '?taskType=' + this.importResultData.taskType + '&taskValue='
      this.importResultData.taskValue
      window.open(url, '_self')
    },
    // 邮箱列表数据上移
    handleEmailToTop(index, chooseId, item) {
      this.articleUpOrBottom(chooseId, 'up', item)
    },
    //  邮箱列表数据下移
    handleEmailToBottom(index, chooseId, item) {
      this.articleUpOrBottom(chooseId, 'down', item)
    },
    //  邮箱列表数据上移下移接口
    articleUpOrBottom(id, type, item) {
      let params = {
        id: id,
        sequence: type === 'up' ? 1 : -1
      }
      EmailUpOrBottom(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message({
            type: 'success',
            message: '移动成功!'
          })
          this.getEmailList(this.emailTablePageObj, 'move', item)
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 邮箱列表数据删除
    handleEmailDeleteItems(chooseId) {
      let params = {
        id: chooseId
      }
      DeleteEmail(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getEmailList('delete')
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 获取每个邮箱的查看收件邮箱
    getExportEmailList(pageParams = {}, type, item) {
      let { page = 1, size = 10 } = pageParams
      let params = {
        magEmailId: this.chooseEmailItem.id,
        page: page - 1,
        size
      }
      ExportEmailList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.exportEmailTablePageObj = {
            ...this.exportEmailTablePageObj,
            page,
            size,
            total: data.totalElements
          }
          this.exportEmailTableData = data.content
          if (this.exportEmailTableData.length > 0) {
            this.$nextTick(() => {
              this.$refs.importEmailList.handleRowClick(this.exportEmailTableData[0])
            })
          } else {
            this.$refs.importEmailList.initData()
            this.handleExportEmailRowClick('')
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 每个邮箱的查看收件邮箱列表点击每一行
    handleExportEmailRowClick(chooseId) {
      this.exportEmailTableData.map((item) => {
        if (item.id === chooseId) {
          this.chooseExportEmailItem = item
        }
      })
      this.$emit('changeTask', chooseId)
    },
    // 每个邮箱的查看收件邮箱列表数据删除
    handleExportEmailDeleteItems(chooseId) {
      let params = {
        ids: chooseId
      }
      DeleteExportEmailListItem(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getExportEmailList(this.exportEmailTablePageObj)
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 刷新
    addColumnClick(event) {
      if (event && event.target.className === 'refresh') {
        let params = {
          id: this.chooseEmailItem.id
        }
        Refresh(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { message } = res.data
            this.$message.success(message)
          } else {
            const { message = '' } = res.data
            this.$message.error(message)
          }
        })
      }
    }
  }
}
</script>
<style lang="scss">
.outgoingMailboxManagement {
  .delete,
  .up-bottom-btns {
    float: left;
  }
  .emailTableList .el-upload-list--text {
    display: none;
  }
  .addColumnContent {
    width: 210px;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .refresh {
      color: #2644ca;
      cursor: pointer;
    }
  }
}
.el-message--success {
  z-index: 4000 !important;
}
</style>
<style lang="scss" scoped>
.outgoingMailboxManagement {
  .center_2 {
    min-height: 200px;
  }
  .push-days {
    display: flex;
    align-items: center;
  }
  //新增邮箱按钮
  .new-btn {
    margin: 0 10px 10px 0;
  }
  .red-color {
    color: red;
  }
}
</style>
